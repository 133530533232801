<template>
  <div>
    <div class="canvas-wrapper" ref="canvasWrapper" style="text-align: center">
      <img :src="invoiceImg" alt="" style="width: 100%" />
    </div>
    <div id="invoice-wrapper" v-if="showHtml">
      <div class="invoice-title">
        <div class="invoice-type">
          <p class="name">
            电子发票({{
              getVal("Header.InherentLabel.GeneralOrSpecialVAT.LabelName")
            }})
          </p>
          <div class="bold-weight"></div>
          <div class="bold-thin"></div>
        </div>
        <div class="invoice-factor">
          <div class="invoice-code" style="margin-bottom: 2px">
            发票号码：<span>{{
              getVal("TaxSupervisionInfo.InvoiceNumber")
            }}</span>
          </div>
          <div class="invoice-date">
            开票日期：<span>{{
              getVal("EInvoiceData.BasicInformation.RequestTime")
            }}</span>
          </div>
        </div>
      </div>

      <table class="invoice-table">
        <!--        公司信息 { -->
        <tr>
          <td>
            <table class="company-info border-bottom">
              <tr>
                <td style="width: 2%" class="border-right padding">
                  购买方信息
                </td>
                <td class="padding">
                  <p style="margin-bottom: 10px">
                    名称：<span>{{
                      getVal("EInvoiceData.BuyerInformation.BuyerName")
                    }}</span>
                  </p>
                  <p>
                    统一社会信用代码/纳税人识别号：<span>{{
                      getVal("EInvoiceData.BuyerInformation.BuyerIdNum")
                    }}</span>
                  </p>
                </td>
                <td style="width: 2%" class="border-right border-left padding">
                  销售方信息
                </td>
                <td class="padding">
                  <p style="margin-bottom: 10px">
                    名称：<span>{{
                      getVal("EInvoiceData.SellerInformation.SellerName")
                    }}</span>
                  </p>
                  <p>
                    统一社会信用代码/纳税人识别号：<span>{{
                      getVal("EInvoiceData.SellerInformation.SellerIdNum")
                    }}</span>
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <!--    } 公司信息-->

        <!--  税务科目 { -->
        <tr>
          <td class="padding border-bottom">
            <table class="subject-info">
              <thead>
                <tr>
                  <td style="width: 10%">项目名称</td>
                  <td style="width: 10%">规格型号</td>
                  <td style="width: 5%">单 位</td>
                  <td style="width: 5%" class="text-right">数 量</td>
                  <td style="width: 15%" class="text-right">单 价</td>
                  <td style="width: 10%" class="text-right">金 额</td>
                  <td style="width: 15%">税率/征收率</td>
                  <td style="width: 10%" class="text-right">税 额</td>
                </tr>
              </thead>
              <tr>
                <td colspan="10" style="vertical-align: top">
                  <table style="width: 100%; color: #000">
                    <tr
                      v-for="(item, index) of getVal(
                        'EInvoiceData.IssuItemInformation'
                      )"
                      :key="index"
                    >
                      <td style="width: 10%">{{ item.ItemName }}</td>
                      <td style="width: 10%">{{ item.SpecMod }} &nbsp;</td>
                      <td style="width: 5%">{{ item.MeaUnits }} &nbsp;</td>
                      <td style="width: 5%" class="text-right">
                        {{ item.Quantity }}
                      </td>
                      <td style="width: 15%" class="text-right">
                        {{ item.UnPrice }}
                      </td>
                      <td style="width: 10%" class="text-right">
                        {{ item.Amount }}
                      </td>
                      <td style="width: 15%">{{ item.TaxRate * 100 }}%</td>
                      <td style="width: 10%" class="text-right">
                        {{ parseFloat(item.ComTaxAm).toFixed(2) }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tfoot>
                <tr>
                  <td>合&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right">
                    <span
                      >¥{{
                        getVal(
                          "EInvoiceData.BasicInformation.TotalAmWithoutTax"
                        )
                      }}</span
                    >
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span
                      >¥{{
                        getVal("EInvoiceData.BasicInformation.TotalTaxAm")
                      }}</span
                    >
                  </td>
                </tr>
              </tfoot>
            </table>
          </td>
        </tr>
        <!--   } 税务科目-->

        <!--   特殊明细 { -->
        <tr v-if="getVal('EInvoiceData.SpecificInformation.FreightService')">
          <td>
            <div class="freight-service-wrapper">
              <table class="freight-service">
                <thead>
                  <tr>
                    <td style="width: 20%">运输工具类型</td>
                    <td style="width: 20%">运输工具牌号</td>
                    <td style="width: 20%">起始地</td>
                    <td style="width: 20%">到达地</td>
                    <td style="width: 20%">运输货物名称</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, i) of getVal(
                      'EInvoiceData.SpecificInformation.FreightService'
                    )"
                    :key="i"
                  >
                    <td>{{ row.TypeOfTransportationMeans }}</td>
                    <td>{{ row.TransportationMeansNo }}</td>
                    <td>{{ row.Departure }}</td>
                    <td>{{ row.Destination }}</td>
                    <td>{{ row.NameOfGoodsTransported }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <!--  } 特殊明细  -->

        <!--   税价合计 { -->
        <tr>
          <td>
            <table class="tax-total">
              <tr class="border-bottom">
                <td
                  class="border-right padding"
                  style="width: 30%; text-align: center"
                >
                  价税合计(大写)
                </td>
                <td class="padding">
                  <span>{{
                    getVal(
                      "EInvoiceData.BasicInformation.TotalTax-includedAmountInChinese"
                    )
                  }}</span>
                </td>
                <td class="padding">
                  (小写)
                  <span
                    >¥{{
                      getVal(
                        "EInvoiceData.BasicInformation.TotalTax-includedAmount"
                      )
                    }}</span
                  >
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <!--   } 税价合计-->

        <!--   备注 { -->
        <tr>
          <td>
            <table class="desc">
              <tr>
                <td class="padding border-right" style="width: 2%">备注</td>
                <td class="padding">
                  <span>
                    {{ getVal("EInvoiceData.AdditionalInformation.Remark") }}
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <!-- }  备注 -->
      </table>

      <p class="opener">
        开票人：<span>{{
          getVal("EInvoiceData.BasicInformation.Drawer")
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";

export default {
  name: "InvoiceView",

  data: function () {
    return {
      showHtml: true,
      invoiceData: {},
      invoiceImg: "",
    };
  },

  methods: {
    getVal(valPath) {
      let parts = valPath.split(".");

      let result = this.invoiceData;
      for (let part of parts) {
        result = result[part];

        if (!result) return "";
      }

      return result;
    },

    xmlToJson(xml) {
      // 创建解析器对象
      let parser = new DOMParser();

      // 使用解析器对象解析XML
      let xmlDoc =
        typeof xml === "string" ? parser.parseFromString(xml, "text/xml") : xml;
      // 创建JSON对象
      let obj = {};
      // 获取根节点
      let rootNode = xmlDoc;
      // 如果根节点存在子节点
      if (rootNode.hasChildNodes()) {
        // 遍历子节点
        for (let i = 0; i < rootNode.childNodes.length; i++) {
          let node = rootNode.childNodes[i];
          // 如果节点是元素节点
          if (node.nodeType === 1) {
            // 如果节点不存在子节点
            if (
              node.childNodes.length === 1 &&
              node.firstChild.nodeType === 3
            ) {
              // 将节点的文本值作为属性值添加到JSON对象中
              obj[node.nodeName] = node.firstChild.nodeValue;
            } else {
              // 如果节点存在子节点，递归调用自身，将返回值作为属性值添加到JSON对象中
              let jsn = this.xmlToJson(node);
              jsn = Object.keys(jsn).length === 0 ? "" : this.xmlToJson(node);

              // list情况
              if (
                ["IssuItemInformation", "FreightService"].includes(
                  node.nodeName
                )
              ) {
                obj[node.nodeName] = [];

                obj[node.nodeName].push(jsn);
              } else {
                obj[node.nodeName] = jsn;
              }
            }
          }
        }
      }
      // 返回JSON对象
      return obj;
    },

    toImage() {
      // 获取HTML元素
      // 使用html2canvas将HTML转换为canvas图像
      html2canvas(document.getElementById("invoice-wrapper")).then((canvas) => {
        this.showHtml = false;

        this.invoiceImg = canvas.toDataURL("image/png");
      });
    },

    // 获取参数的函数
    getParameterByName(name) {
      // 如果没有传入 URL，则获取当前页面的 URL
      const url = window.location.href;
      // 对 URL 进行解析，获取查询参数部分
      name = name.replace(/(\[|\])/g, "\\$&");
      let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      // 对获取到的参数进行解码并返回
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
  },

  created() {
    const xml = this.getParameterByName("xml");
    if (xml) {
      let { EInvoice } = this.xmlToJson(xml);

      if (!EInvoice) {
        alert("xml信息不合法");
      }

      this.invoiceData = EInvoice;
    }

    console.log(this.invoiceData);
  },

  mounted() {
    this.toImage();
  },
};
</script>

<style scoped>
#invoice-wrapper {
  border: 1px solid #000;
  width: 780px;
  margin: 0 auto;
  padding: 20px;
  color: #8b0100;
  font-size: 12px;
  font-family: SimSun, Roboto, Arial, sans-serif;
}

.canvas-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

#invoice-wrapper span {
  color: #000;
}

.freight-service-wrapper {
  border-bottom: 1px solid #8b0100;
  min-height: 180px;
}
.freight-service {
  width: 100%;
  text-align: center;
}

.freight-service thead {
  border-bottom: 1px solid #8b0100;
}

.freight-service tbody {
  color: #000000;
}

.invoice-type {
  margin: 0 auto;
  width: 280px;
  text-align: center;
  margin-bottom: 40px;
}

.invoice-title {
  position: relative;
}

.invoice-title .invoice-factor {
  position: absolute;
  top: 10px;
  right: 10px;
}

.invoice-type .name {
  font-size: 24px;
}

.invoice-type .bold-weight {
  margin-top: 10px;
  border-bottom: 1px solid #8b0100;
  margin-bottom: 2px;
}

.invoice-type .bold-thin {
  border-bottom: 1px solid #8b0100;
}

.company-info {
  width: 100%;
}

.invoice-table {
  border: 1px solid #8b0100;
  width: 100%;
  margin: 0 auto;
}

.subject-info {
  width: 100%;
  text-align: center;
  min-height: 140px;
}

.subject-info tbody {
  color: #000;
}

.tax-total {
  width: 100%;
}

.desc {
  height: 80px;
}

.opener {
  padding: 15px 20px;
}

.table tr {
  border: 1px solid #8b0100;
}

table {
  border-collapse: collapse;
}
</style>
