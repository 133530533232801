<template>
  <div id="app">
    <invoice-view />
  </div>
</template>

<script>
import InvoiceView from "@/views/invoice-view";

export default {
  name: "App",
  components: {
    InvoiceView,
  },
};
</script>
